import React, { Component } from "react";

class Passivos_Opcoes extends Component {
	render() {
		return (
			<div>
				{this.props.func}
				<br />
			</div>
		);
	}
}

export default Passivos_Opcoes;
